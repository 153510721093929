<template>
  <ContentWrapper>
    <breadcrumb name="Aktuelles" />
    <div class="container animated-fast fadeInUp">
      <div class="row">
            <div class="col-sm-4 mb-4" v-for="post in posts" :key="post.id">
                <post-preview :post="post"></post-preview>
            </div>
      </div>
    </div>

  </ContentWrapper>
</template>
<style lang="scss">


</style>
<script>
import PostPreview from "../components/PostPreview.vue";

export default {
    components: {PostPreview},
        data() {
            return {
              posts: []
            }
        },
        mounted() {
            this.loading = true;
            return this.$api.get('news/posts?language=' + this.$i18n.locale).then(response => {
                this.posts = response.data.data;
                this.loading = false;
            });
        }
    }
</script>
